<template>
  <div
    v-editable="blok"
    class="grid"
    :class="blok.styles"
  >
    <component :is="childBlok.component" v-for="(childBlok, index) in blok.columns" :key="index" :blok="childBlok" />
  </div>
</template>

<script setup>
defineProps({
  blok: {
    type: Object,
    required: true,
  },
})
</script>
